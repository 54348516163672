import React from 'react';
import wesLogo from '../images/SplashNice.png';// Replace with the correct path to your image
import './SpinningLogo.css';

const SpinningImage = ({ size }) => {
  return (
    <img
      src={wesLogo}
      alt="Wes Logo"
      style={{
        width: `${size}px`,
        height: `${size}px`, // To maintain aspect ratio
        animation: 'spin 2s linear infinite', // 2s duration, linear timing, infinite loop
      }}
    />
  );
};

export default SpinningImage;
