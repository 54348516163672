import React from 'react';
import { StyleSheet, Text, View } from 'react-native';
import "./Split.css"
import slnBadge from '../images/SlnBadge.png';



const SlnSection = () => {

    const headingStyle = {
        color: '#ffea00', // Replace with your desired color
      };

    const textStyle = {
        color: 'black', // Replace with your desired color
      };

    return (
        <div className="vision-sectionP">
        <div className="vision-container">
          <div className="image-container">
            <img src={slnBadge} alt="Vision" className='slnImage' />
          </div>
          <div className="text-container">
          <h2 style={headingStyle}>Snapchat Lens Network Developer</h2>
            <p style={textStyle}>I am recognized by Snap AR as a strong developer and awarded access to the Snapchat Lens Network.</p>
          </div>
        </div>
      </div>
    );
}
  
export default SlnSection;