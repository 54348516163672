import React from 'react';
import { StyleSheet, Text, View } from 'react-native';
import "./BespokeVR.css";

const BespokeVR = () => {



  return (
    <div className="vision-sectionAR">
      <div className="vision-container">
        <div className="Btext-container">
            <h2 className='socialHeading'> Bespoke VR Experiences</h2>
            <p className='socialText'>Bespoke VR experiences offer brands a unique way to stand out in the digital landscape. By creating custom virtual reality content, brands can immerse users in tailored and memorable interactions. This personalized approach enhances brand engagement and fosters a deeper connection with the audience. </p>
            <p className='socialText'>Our roots are in VR experiences, so we have the skills you need to launch VR experience for your brand.</p>

            <a href="Contact" className="cta-button">Learn More</a>
        </div>
        {/* <div className="image-container">
            
        </div> */}
      </div>
    </div>
  );
}


export default BespokeVR;