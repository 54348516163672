import { StatusBar } from 'expo-status-bar';
import { StyleSheet, Text, View } from 'react-native';
import React, { useState } from 'react';
import TestModule from './myModules/TestModule'; 
import CameraComponent from './myModules/CameraKitModule';
import {BrowserRouter as Router,Routes,Route,Link, Switch} from "react-router-dom";


//Core
import NavBar from './CoreComponents/NavBar';

// import Home component

import Home from "./pages/Home";
// import AR component
import AR from "./pages/AR";


// import Contact component
import Contact from "./pages/Contact";

// import Portfolio component
import Portfolio from "./pages/Portfolio";

// import Privacy component
import Privacy from "./pages/Privacy";

// import Business component
import Business from "./pages/Business";

//import Physical component
import Physical from "./pages/Physical";


//Maybe wanna replace * to a 404 page later?
export default function App() {

  return (
    <Router>
      <Routes>
        <Route path="*" element={<Home />} /> 
        <Route path="/Ar" element={<AR />} />
        <Route path="/Contact" element={<Contact />} />
        <Route path="/Business" element={<Business />} />
        <Route path="/Portfolio" element={<Portfolio />} />
        <Route path="/Privacy" element={<Privacy />} />
        <Route path="/Physical" element={<Physical />} />
      </Routes>
    </Router>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#374B51',
    alignItems: 'center',
    justifyContent: 'center',
  },
  navBar: {
    width: '100%',
  },
});