import React from 'react';
import { StyleSheet, Text, View } from 'react-native';
import "./SocialAR.css";
import spectaclesLogo from '../images/spectacles.jpg';


const SocialAR = () => {



  return (
    <div className="vision-sectionAR">
      <div className="vision-container">
        <div className="Btext-container">
            <h2 className='socialHeading'> Social AR</h2>
            <p className='socialText'>Social Augmented Reality (AR) is a game-changer for brands. It adds fun filters and effects to everyday experiences, making brand content more interactive and shareable.</p>
            <p className ='socialText'>We have a wealth of experience in helping top brand bring their marketing visions to life through Social AR, click to see some examples</p>
            
            <a href="AR" className="cta-button">See Examples</a>
            <br>
            </br>
            <br>
            </br>
            <br>
            </br>
            <br>
            </br>
            <a href="Contact" className="cta-button">Learn More</a>
        </div>
        {/* <div className="image-container">
        <img src={spectaclesLogo} alt="Vision" className='slnImage' />
        </div> */}
      </div>
    </div>
  );
}


export default SocialAR;