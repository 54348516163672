import React from 'react';
import { StyleSheet, Text, View } from 'react-native';
import "./Split.css"
import donutLogo from '../images/donutLogo.png';



const Winner = () => {

  const headingStyle = {
    color: 'pink', // Replace with your desired color
  };

  const textStyle = {
    color: 'black', // Replace with your desired color
  };

  return (
    <div className="vision-sectionP">
      <div className="vision-container">
      <div className="image-container">
          <img src={donutLogo} alt="Vision" className='slnImage' />
        </div>
        <div className="text-container">
          <h2 style={headingStyle}>Lensathon 22 Bronze Winner</h2>
          <p style={textStyle}>My lens Donut Defence won the bronze prize in the Snap AR online lensathon in 2022. This lens utilised many technologies such as world mesh and connected lenses. This lens resulted in a fun spatial multiplayer game with donuts, and who doesn't love donuts. Try it out <a href="https://devpost.com/software/donut-defence">here</a>.</p>
        </div>
      </div>
    </div>
  );
}
  
export default Winner;