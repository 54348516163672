import React, { useState, useEffect, useRef } from 'react';
import { StyleSheet, Text, View } from 'react-native';
import NavBar from '../CoreComponents/NavBar';
import SnapExamples from '../myModules/SnapExamples';
import SnapComplexExample from '../myModules/SnapComplexExample';
import VRGeneric from '../myModules/VRGeneric';
import "./AR.css"

//vids for main
//import BaloonVid from '../images/videos/baloon.mp4';
import GlitchTvVid from "../images/videos/compressed/GlitchTv_compressed.mp4";
//import FireBreath from "../images/videos/FireBreath.mp4";
//import Hypnotise from "../images/videos/Hypnotise.mp4";
import Baubleify from "../images/videos/compressed/Baubleify_compressed.mp4";
//import FacePaint from "../images/videos/facePaint.mp4";
import gumBall from "../images/videos/compressed/compressed_gum.mp4";
import dance from "../images/videos/compressed/dance_compressed.mp4";
import fireBreathTwo from "../images/videos/compressed/fireBreathTwo_compressed.mp4";
import baloonFaceTwo from "../images/videos/compressed/baloonFaceTwo_compressed.mp4";


//vids for complex
import solarQuiz from "../images/videos/compressed/solarQuiz_compressed.mp4";
import donutDefence from "../images/videos/compressed/donutDefenceTwo_compressed.mp4";
import magicRealm from "../images/videos/compressed/magicRealm_compressed.mp4";

//snapcodes
import donutCode from "../images/snapcodes/donutCode.png";
import solarQuizCode from "../images/snapcodes/solarQuizCode.png";
import magicRealmCode from "../images/snapcodes/magicRealmCode.png";
//import foodCode from "../images/snapcodes/foodCode.png";
//import brandCode from "../images/snapcodes/brandCode.png";

import squakVid from "../images/videos/compressed/squakVid_compressed.mp4";
import TAHero from '../images/TAHero.png'
import PPHero from '../images/PPHero.png'

import CameraComponent from '../myModules/CameraKitModule';
import VRExamples from '../myModules/SquakHero';




import PortfolioHeadTitle from '../myModules/PortfolioHeadTitle';
import { Button } from 'react-bootstrap';

const names = ["Gumball Machine", "Glitch TV", "Dance Time", "Fire Mouth", "Baubleify", "Face Balloon", "Tilt Example"];
const lensIndexes = [1, 8, 2, 7, 5, 9, 0];

const ArPage = () => {
  const myRef = useRef();

  const closeSnap = () => {
    console.log("closing snap");
    setIsPressed(false);
  };


  const RefBit = React.forwardRef((props, ref) => {
    return (
      <div ref={ref}>
        {isPressed && (
        <>
        <View style={styles.camKit}> 
          <CameraComponent currentLens={lensIndex} onUpdateLens={onUpdateLens} ref={ref}/>
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            <button onClick={closeSnap}  style={{ backgroundColor: "#a84032", fontSize: '24px', width: '20%'}}> Close </button>
          </div>
        </View>
        </>
        )}
      </div>
    );
  });



  
  const [isPressed, setIsPressed] = useState(false);
  const [lensIndex, setLensIndex] = useState(1);
  const handleButtonClick = (videoName) => {
    setIsPressed(true);
    if(names.indexOf(videoName) == -1){
      return
    }
    scrollToCK() // Scroll to the camera kit

    setLensIndex(lensIndexes[names.indexOf(videoName)]);
    onUpdateLens(lensIndexes[names.indexOf(videoName)]);
  };


  const onUpdateLens = (lensIndex) => {
    // Logic to handle lens update in the parent component
    console.log(`Updating lens to index: ${lensIndex}`);

    setLensIndex(lensIndex);
    
  };


  function scrollToCK() {
    myRef.current.scrollIntoView({ behavior: 'smooth' });
  }

  function useWindowWidth() {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  
    useEffect(() => {
      const handleResize = () => setWindowWidth(window.innerWidth);
  
      window.addEventListener('resize', handleResize);
  
      // Clean up the event listener when the component unmounts
      return () => window.removeEventListener('resize', handleResize);
    }, []);
  
    return windowWidth;
  }


  const windowWidth = useWindowWidth();
  const videoStyle = windowWidth < 700 ? styles.mobileVideos : styles.wideVideos;
  console.log(windowWidth + " is the window width");



  return (
    
    <View style={styles.container}>
      
      <View style={styles.navBar}>
        <NavBar />
      </View>
      
      <View style={styles.textContainer}>
      <PortfolioHeadTitle titleText={"Fun"} subTitle={"Step into our world of AR wonders! These experiences are designed to not just impress but go viral, with a focus on ease of use and shareability."} instructions={"Press try to use the effect in your browser."}/>
        <View style={videoStyle}>
        <SnapExamples videoSource={gumBall} videoName={"Gumball Machine"} onButtonClick={handleButtonClick} />
      <SnapExamples videoSource={GlitchTvVid} videoName={"Glitch TV"} onButtonClick={handleButtonClick} />
      <SnapExamples videoSource={dance} videoName={"Dance Time"} onButtonClick={handleButtonClick} />

          <SnapExamples videoSource={fireBreathTwo} videoName={"Fire Mouth"} onButtonClick={handleButtonClick}/>
          <SnapExamples videoSource={Baubleify} videoName={"Baubleify"} onButtonClick={handleButtonClick}/>
          <SnapExamples videoSource={baloonFaceTwo} videoName={"Face Balloon"} onButtonClick={handleButtonClick}/>
      </View>

        {/* <View style={styles.camKit}> 
        {isPressed && <CameraComponent currentLens={lensIndex} onUpdateLens={onUpdateLens} ref={myRef}/>}
        </View> */}

        <RefBit ref={myRef} />

      </View>


      <View style={styles.textContainer}>
      <PortfolioHeadTitle titleText={"Complex"} subTitle={"This set of experiences contain exciting new technologies, making tommorows expereinces possible today."} instructions={"Press try to launch on Snapchat, or scan the snapcode within Snapchat."}/>
        <View style={videoStyle}>
        <SnapComplexExample videoSource={solarQuiz} lensName={"Solar Quiz"} lensLink={"https://www.snapchat.com/unlock/?type=SNAPCODE&uuid=cfffe2709d044f7684aa5366a49dbf80&metadata=01"} lensSnapcode={solarQuizCode}/>
      <SnapComplexExample videoSource={donutDefence} lensName={"Donut Defence"} lensLink={"https://www.snapchat.com/unlock/?type=SNAPCODE&uuid=ca1186ca2e1246189e1e0eb6015f5ba8&metadata=01"}  lensSnapcode={donutCode}/>
      <SnapComplexExample videoSource={magicRealm} lensName={"Magic Realm"} lensLink={"https://www.snapchat.com/unlock/?type=SNAPCODE&uuid=d01b56faac974038836539ba1c17f22f&metadata=01"} lensSnapcode={magicRealmCode}/>
        </View>

      </View>

{/* 
      <View style={styles.textContainer}>
      <PortfolioHeadTitle titleText={"Business"} subTitle={"Brands are imbracing the Social AR journey and are advertising with it, check out some generic examples below."} instructions={"Press try to launch on Snapchat, or scan the snapcode within Snapchat."}/>
        <View style={styles.videos}>
        <SnapComplexExample videoSource={solarQuiz} lensName={"Example Coffee"} lensLink={"https://www.snapchat.com/unlock/?type=SNAPCODE&uuid=cfffe2709d044f7684aa5366a49dbf80&metadata=01"} lensSnapcode={solarQuizCode}/>
        <SnapComplexExample videoSource={solarQuiz} lensName={"Example Bakery"} lensLink={"https://www.snapchat.com/unlock/?type=SNAPCODE&uuid=58fa8c7fbf994cc18c89900d56d92e34&metadata=01"} lensSnapcode={foodCode}/>
        <SnapComplexExample videoSource={solarQuiz} lensName={"Example Brand"} lensLink={"https://www.snapchat.com/unlock/?type=SNAPCODE&uuid=cfffe2709d044f7684aa5366a49dbf80&metadata=01"} lensSnapcode={brandCode}/>
        </View>

      </View> */}
      


      <View style={styles.textContainer}>
      <PortfolioHeadTitle titleText={"VR / MR"} subTitle={"We are no strangers when it comes to VR and MR development, here are a few of our titles."} instructions={"Press more to visit the store fronts of our released games."}/>
        <View style={videoStyle}>
          <VRGeneric imageSource={TAHero} gameName={"Take Aim"} gameLink={"https://sidequestvr.com/app/3169/take-aim"}/>
      <VRExamples videoSource={squakVid} gameName={"Squawk"}/>
      <VRGeneric imageSource={PPHero} gameName={"Puzzling Paths"} gameLink={"https://sidequestvr.com/app/4950/puzzling-paths"}/>
        </View>

      </View>


    </View>
  );
}


export default ArPage;


const styles = StyleSheet.create({
    container: {
      flex: 1,
      backgroundColor: '#374B51',
    },
    navBar: {
      width: '100%',
    },
    textContainer: {
      
      },
    wideVideos: {
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'space-between',
        flexWrap: 'wrap', // Allow items to wrap onto the next line
      },
      mobileVideos: {
        display: 'flex',
        flexDirection: 'column',

      },

      
      camKit: {
        textAlign: 'center',
      },
  });
  