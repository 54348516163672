import React from 'react';
import { StyleSheet, Text, View } from 'react-native';
import NavBar from '../CoreComponents/NavBar';

const Privacy = () => {


  return (
    <View style={styles.container}>
      <View style={styles.navBar}>
        <NavBar />
      </View>
      <View style={styles.textContainer}>
        <Text style={styles.header}>Privacy Policy</Text>
        <Text style={styles.text}>
        <br></br>         <br></br>         <br></br>
        No personal data is knowingly collected via any of the Weird Eye Studios apps on App Lab or the Oculus Store.
        <br></br>         <br></br>
        My apps have no external hyperlinks in game so there is no concern about visiting another service with a different privacy policy.
        <br></br>         <br></br>
        If there are any issues or questions do not hesitate to contact me at andrewdouglas004@gmail.com.
        <br></br>         <br></br>
        If you decide to fill out the contact page that data is held securly and only used for the use of reaching out about your enquiry.
        <br></br>         <br></br>
        This policy is effective as of the 18th of August 2020
        <br></br>         <br></br>
        https://www.oculus.com/legal/privacy-policy/?locale=en_GB

        </Text>

      </View>
    </View>
  );
}

export default Privacy;


const styles = StyleSheet.create({
    container: {
      flex: 1,
      backgroundColor: '#374B51',
    },
    navBar: {
      width: '100%',
    },
    textContainer: {
        marginTop: 10, // Adjust this value as needed
        padding: 25,

      },
      header: {
        fontSize: 40,
        fontWeight: 'bold',
        color: '#ACFF9B',
        textAlign: 'center',
        fontFamily: 'Source Code Pro, monospace',
        fontWeight: 700,
      },
      text: {
        fontSize: 20,
        color: '#ffffff',
        textAlign: 'center',
        fontFamily: 'Source Code Pro, monospace',
        fontWeight: 700,
      },
      
      
  });
  