import React from 'react';
import { StyleSheet, Text, View, Dimensions} from 'react-native';
import NavBar from '../CoreComponents/NavBar';

import CardProduct from '../PhysicalComponents/CardProduct';
import CardProductTwo from '../PhysicalComponents/CardProductTwo';
import CardBlurb from '../PhysicalComponents/CardBlurb';


//image imports
import SpaceCard from '../PhysicalComponents/SpaceCard.JPG';
import DonutCard from '../PhysicalComponents/DonutCard.JPG';
import HeartCard from '../PhysicalComponents/HeartCard.JPG';
import PartyCard from '../PhysicalComponents/PartyCard.jpg';
import StarCard from '../PhysicalComponents/StarCard.jpg';


const Physical = () => {
    const { width } = Dimensions.get('window');
    const isMobile = width <= 1000;

    return (
      <View style={styles.container}>
        <View style={styles.navBar}>
          <NavBar />
        </View>
        <View style={styles.textContainer}>
          <Text style={styles.header}>Physical Products</Text>
          <CardBlurb />

          <View style={isMobile ? [styles.verticalCardContainer] : styles.cardContainer}>
            <CardProduct price={5} name={"You are out of this world!"} image={SpaceCard} description={"Works perfectly as a Birthday card or to congratulate the recipient"} link={"https://www.etsy.com/uk/listing/1721890498/space-animated-augmented-reality-card"}/>
            <CardProduct price={5} image={DonutCard} name={"Donut worry its your Birthday!"} description={"Designed for Birthdays"} link={"https://www.etsy.com/uk/listing/1736061797/donut-animated-birthday-card-with-audio"}/>
            <CardProduct price={5} image={HeartCard} name = {"With love"} description={"Show some love to someone special in your life"} link={"https://www.etsy.com/uk/listing/1736077567/love-heart-augmented-reality-card"}/>
          </View>

          <View style={isMobile ? [styles.verticalCardContainer] : styles.cardContainer}>
            <CardProductTwo price={5} image={PartyCard} name = {"Party Time"} description={"Perfect as a party invite or as a celebration card!"} link={"https://www.etsy.com/uk/listing/1736079373/animated-party-card-with-music-party"}/>
            <CardProductTwo price={5} image={StarCard} name = {"You are a star"} description={"This card is perfect for any occasion"} link={"https://www.etsy.com/uk/listing/1721888526/star-animated-card-with-music-augmented"}/>
          </View>

        </View>
      </View>
    );
  }
  export default Physical;

  const styles = StyleSheet.create({
    container: {
      flex: 1,
      backgroundColor: '#374B51',
      
    },
    navBar: {
      width: '100%',
    },
    textContainer: {
      marginTop: 20, // Adjust this value as needed
      padding: 25,
    },
    header: {
      fontSize: 40,
      fontWeight: 'bold',
      color: '#ACFF9B',
      textAlign: 'center',
      fontFamily: 'Source Code Pro, monospace',
      fontWeight: 700,
    },
    text: {
      fontSize: 20,
      color: '#ffffff',
      textAlign: 'center',
      fontFamily: 'Source Code Pro, monospace',
      fontWeight: 700,
    },
    cardContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      paddingLeft: "5VW",
      paddingRight: "5VW",
      paddingBottom: "5VH",
      marginBottom: 20, // Add this line to create a bigger gap between the card containers
    },
    verticalCardContainer: {
      flexDirection: 'column',
      alignItems: 'center',
    },
    cardProduct: {
      flex: 1, // Add this line to make the cards equally spaced
    },

  });


