import React, { useEffect, useState } from 'react';
import { StyleSheet, Text, View } from 'react-native';
import ScaleText from "react-scale-text";
import "./SquakHero.css"

const VRExamples = ({ videoSource, gameName }) => {

    const [isMobile, setIsMobile] = useState(false);

    const handleButtonClick = () => {
        // Call the callback function and pass the videoName
        console.log(lensLink);
        var newTab = window.open();
        newTab.opener = null;
        newTab.location.href = lensLink;
      };


    return (
        <div className="vexampleBase">
        <div className='vvideoBase'>
            <video  className='vvideo' src={videoSource} type="video/mp4" autoPlay muted loop playsInline/>

        </div>
        <p className='sHero'>{gameName}</p>
        <div  className='snapText'>
            
            <br/>

                <button style={{ fontSize: isMobile ? '12px' : '24px' }}> Coming Soon </button>
            </div>
        </div>
    );
}
  
export default VRExamples;

//                    style={styles.backgroundVideo}