import React from 'react';
import './CardProduct.css';
import {Dimensions} from 'react-native';
import DefaultImage from './defaultImage.png';

const CardProduct = ({ image, name, price, description, link }) => {
    const { width } = Dimensions.get('window');
    const isMobile = width <= 1000;

    const handleButtonPress = () => {
        window.open(link, '_blank');
    }
    return (
        <div className={`${isMobile ? 'cardM' : 'card'}`}>
            <img src={image || DefaultImage} alt={name || "lol"} className="card-image" />
            <div className="card-details">
            <div className="content-container">
            <h3 className="card-name">{name || "Card Here"}</h3>
            <h3 className="card-description">{description || "Card Here"}</h3>
        </div>
        <p className="card-price">£{price}</p>
        <button className="card-button" onClick={handleButtonPress}>Buy Now</button>
    </div>
        </div>
    );
};

export default CardProduct;