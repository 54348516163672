import React from 'react';
class TestModule extends React.Component {

  // The render method returns the JSX that represents the component's UI
  render() {
    // JSX syntax looks similar to HTML, but it's actually JavaScript
    return (
      <div>
        <h1>Hello, World!</h1>
        <p>This is a simple React component.</p>
      </div>
    );
  }
}

export default TestModule;
