import React, { useState, useEffect } from 'react';
import { StyleSheet, Text, View } from 'react-native';
import NavBar from '../CoreComponents/NavBar';


import SlnSection from '../PortfolioComponents/SLN';
import OculusStart from '../PortfolioComponents/OculusStart';
import VRGames from '../PortfolioComponents/VRGames';
import Spectacles from '../PortfolioComponents/Spectacles';
import Winner from '../PortfolioComponents/Winner';
import Sb from '../PortfolioComponents/Sb';


const Portfolio = () => {


  function useWindowWidth() {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  
    useEffect(() => {
      const handleResize = () => setWindowWidth(window.innerWidth);
  
      window.addEventListener('resize', handleResize);
  
      // Clean up the event listener when the component unmounts
      return () => window.removeEventListener('resize', handleResize);
    }, []);
  
    return windowWidth;
  }

  const windowWidth = useWindowWidth();





  return (




    <View style={styles.container}>
      <View style={styles.navBar}>
        <NavBar />
      </View>

      {windowWidth > 700 && (
      <View>
      <View style={styles.sideBySide}>
        <SlnSection/>
        <OculusStart/>
      </View>
      <View style={styles.sideBySide}>
        <VRGames/>
        <Spectacles/>
        </View>
        <View style={styles.sideBySide}>
        <Winner/>
        <Sb/>
        </View>
      </View>
        )}

        

    {windowWidth < 700 && (
      <View style={{paddingRight: '10%'}}>
      <SlnSection/>
      <OculusStart/>
      <VRGames/>
      <Spectacles/>
      <Winner/>
      <Sb/>
      </View>
    )}
        
    </View>
  );
}

export default Portfolio;


const styles = StyleSheet.create({
    container: {
      flex: 1,
      backgroundColor: '#374B51',

    },
    navBar: {
      width: '100%',
    },
    textContainer: {
        marginTop: 10, // Adjust this value as needed
      },
    sideBySide: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
      },
  });
  