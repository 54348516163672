import React from 'react';
import { StyleSheet, Text, View } from 'react-native';
import NavBar from '../CoreComponents/NavBar';

//imports
import SocialAR from '../businessComponents/SocialAR';
import BespokeVR from '../businessComponents/BespokeVR';
import ARApps from '../businessComponents/ARApps';

const Business = () => {

  return (
    <View style={styles.container}>
      <View style={styles.navBar}>
        <NavBar />
        <Text style={styles.titleStyle}> Tomorrow's Experiences Today</Text>
      </View>
      <View style={styles.contentRow}>
      <SocialAR />
      <BespokeVR />
      </View>
    </View>
  );
}

export default Business;


const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#374B51',
  },
  navBar: {
    width: '100%',
  },
  contentRow: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    flexWrap: 'wrap', // Allow items to wrap onto the next line
  },
  titleStyle: {
    marginTop: 60,
    color: '#ACFF9B',
    fontWeight: 'bold',
    textAlign: 'center',
    fontSize: '3em',
    fontWeight: 700,
    fontFamily: 'Source Code Pro, monospace',

  }
});