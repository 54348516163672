import React, { useState } from 'react';
import { StyleSheet, Text, View } from 'react-native';
import NavBar from '../CoreComponents/NavBar';
import 'bootstrap/dist/css/bootstrap.min.css';
import axios from 'axios';
import Constants from 'expo-constants';



const ContactPage = () => {
  const [validationError, setValidationError] = useState('');
  const [hasFilledOut, setHasFilledOut] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
  });
  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.id]: e.target.value,
    });
  };

  const validateInputs = () =>{
    if(formData.name == ''){
      return false;
    }
    if(formData.email == ''){
      return false;
    }
    if(formData.message == ''){
      return false;
    }
    return true;
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    const date = new Date();
    var data = {
      "name": formData.name,
      "email": formData.email,
      "message": formData.message,
      "date": date.toISOString()
      
    }

    if(!validateInputs()){
      setValidationError('Please fill in all fields.');
      return
    }


    try {
      const response = await axios({
        method: "POST",
        url: "https://api.baserow.io/api/database/rows/table/225751/?user_field_names=true",
        headers: {
          Authorization: "Token " + Constants.expoConfig.extra.DATABASE,
          "Content-Type": "application/json"
        },
        data
      })

      if (response.ok || response.status === 200) {
        console.log('Form submitted successfully!');
        setHasFilledOut(true);

        // You can also reset the form here if needed
      } else {
        console.error('Form submission failed.');
        console.error(response);
      }
    } catch (error) {
      console.error('An error occurred during form submission:', error);
    }
  };

  return (
    <div style={styles.container}>
      <NavBar/>
    {!hasFilledOut && (<div className="container mt-5" style={{backgroundColor: '#374B51', color: 'white'}}>
      <div className="row justify-content-center align-items-center">
        <div className="col-md-6">
          <h2 style={styles.header}>Contact Us</h2>
          <p style={styles.pageContent}>Reach out to help us help you with your XR projects.</p>

          <form onSubmit={handleSubmit}>
            <div className="mb-3">
              <label htmlFor="name" className="form-label">
                Your Name
              </label>
              <input
                type="text"
                className="form-control"
                id="name"
                value={formData.name}
                onChange={handleChange}
              />
            </div>

            <div className="mb-3">
              <label htmlFor="email" className="form-label">
                Your Email
              </label>
              <input
                type="email"
                className="form-control"
                id="email"
                value={formData.email}
                onChange={handleChange}
              />
            </div>

            <div className="mb-3">
              <label htmlFor="message" className="form-label">
                Tell us about your idea
              </label>
              <textarea
                className="form-control"
                id="message"
                rows="5"
                value={formData.message}
                onChange={handleChange}
              ></textarea>
            </div>

            <button type="submit"  style={{width: '100%', textAlign: 'center', alignContent: 'center', alignItems: 'center', justifyContent: 'center'}}>
              Send Message
            </button>
          </form>
          {validationError && <div className="alert alert-danger">{validationError}</div>}
        </div>
      </div>
    </div>)}
    {hasFilledOut && (<div className="container mt-5" style={{backgroundColor: '#374B51'}}>
      <div className="row justify-content-center align-items-center">
        <div className="col-md-6">
          <h2 style={styles.header}>Thank you for reaching out!</h2>
          <p style={styles.pageContent}>We will be in touch with you shortly.</p>
        </div>
      </div>
    </div>)}
    </div>
  );
};

export default ContactPage;


const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#374B51',
    alignItems: 'center',
    justifyContent: 'center',
    fontFamily: 'Source Code Pro, monospace',
    fontWeight: 700,
    fontSize: 20
  },
  form: {
    width: '100%',
    marginTop: 20,
  },
  input: {
    borderWidth: 1,
    borderColor: '#ccc',
    padding: 10,
    marginBottom: 10,
  },
  testBit: {
    justifyContent: 'center',
    alignItems: 'center',

  },
  header: {
    color: '#ACFF9B',
    marginBottom: 20,
    fontFamily: 'Source Code Pro, monospace',
    fontWeight: 700,
    textAlign: 'center',
  },
  pageContent: {
    color: '#ffffff',
    fontSize: 25,
    fontFamily: 'Source Code Pro, monospace',
    fontWeight: 700,
  }
  });
  