import React from 'react';
import "./Split.css"
import { Fade } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css'
import squareMP from '../images/gameSquares/squareMP.png';
import squarePP from '../images/gameSquares/squarePP.png';
import squarePT from '../images/gameSquares/squarePT.png';
import squareTA from '../images/gameSquares/squareTA.png';

const fadeImages = [
  squareMP,
  squarePP,
  squarePT,
  squareTA
];

const buttonStyle = {
  width: "0px",
  background: 'none',
  border: '0px'
};

const properties = {
  prevArrow: <button style={{...buttonStyle }}></button>,
  nextArrow: <button style={{...buttonStyle }}></button>,
  duration: 1500,
}

const VRGames = () => {

    const headingStyle = {
        color: 'pink', // Replace with your desired color
      };

    const textStyle = {
        color: 'black', // Replace with your desired color
      };

    return (
        <div className="vision-sectionP">
        <div className="vision-container">
          <div className="image-container">
          <Fade {...properties}>
        {fadeImages.map((fadeImage, index) => (
          <div key={index}>
            <img style={{ width: '100%' }} src={fadeImage} />
          </div>
        ))}
      </Fade>
          </div>
          <div className="text-container">
          <h2 style={headingStyle}>VR Games</h2>
            <p style={textStyle}>I have shipped multiple titles across both app lab, rift store and go store. Watch this space, a brand new exciting VR game is coming soon.</p>
          </div>
        </div>
      </div>
    );
}
  
export default VRGames;