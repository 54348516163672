import React from 'react';
import { StyleSheet, Text, View } from 'react-native';
import "./PortfolioHeadTitle.css"

const PortfolioHeadTitle = ({titleText, subTitle, instructions}) => {


    return (
        <div className="pBase"> {/* This is the base div for the entire component. */}
        <div className="pContentBase">
            <p className='pTitle'> {titleText}</p>
            <p className='pSubTitle'> {subTitle}</p>
            <p className="pInstructions">{instructions}</p>
      </div>
        </div>
    );
}
  
export default PortfolioHeadTitle;