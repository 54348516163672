import React, { useEffect, useState } from 'react';
import { bootstrapCameraKit, createMediaStreamSource, Transform2D } from "@snap/camera-kit";
import SpinningImage from './SpinningLogo';
import Constants from 'expo-constants';
import attributionlogo from './attribution.png'

const CameraComponent = ({currentLens, onUpdateLens   }) => {
  const [canvasSize, setCanvasSize] = useState({ width: 400, height: 400 });
  const [borderStyle, setBorderStyle] = useState('0px solid #fff');
  const [isLoaded, setIsLoaded] = useState(false);
  const [getSession, setSession] = useState(null);
  const [myLenses, setLenses] = useState(null);
  useEffect(() => {
    const initializeCamera = async () => {

      if(getSession != null){
        //update the lens
        console.log("updating lens")
        var localSession = getSession;
        var localLenses = myLenses;
        await localSession.applyLens(localLenses[currentLens]);
        handleImageLoad();
        return;

        
      }

      
      try {
        const apiToken = Constants.expoConfig.extra.CK_KEY;
        const cameraKit = await bootstrapCameraKit({ apiToken });

        const canvas = document.getElementById("my-canvas");
        const session = await cameraKit.createSession({ liveRenderTarget: canvas });
        setSession(session);
        session.events.addEventListener('error', (event) => {
          if (event.detail.error.name === 'LensExecutionError') {
            console.log('The current Lens encountered an error and was removed.', event.detail.error);
          }
        });

        const stream = await navigator.mediaDevices.getUserMedia({ video: true });
        const source = createMediaStreamSource(stream, { transform: Transform2D.MirrorX, cameraType: 'front' });
        await session.setSource(source);
        await source.setRenderSize(500, 1000);
        const lensToLoad = Constants.expoConfig.extra.CK_LG;
        console.log(lensToLoad);
        const { lenses } = await cameraKit.lensRepository.loadLensGroups([
          lensToLoad
        ]);

        setLenses(lenses);
        console.log(lenses.length)


        await session.applyLens(lenses[currentLens]);


        canvas.focus();
        await session.play();
        setBorderStyle('20px solid #fff');
        setIsLoaded(true);
        console.log("Lens rendering has started!");
        handleImageLoad();
      } catch (error) {
        console.error('Error initializing camera:', error);
      }
    };

    initializeCamera();

    // Cleanup function
    return () => {
      // Perform cleanup (e.g., stop the camera session) if needed
    };
  }, [onUpdateLens]); // Empty dependency array ensures that the effect runs only once on mount

  const handleImageLoad = () => {
    // Get the dynamically changed canvas size
    const canvas = document.getElementById("my-canvas");
    const originalWidth = canvas.width;
    const originalHeight = canvas.height;
  
    // Calculate the new dimensions to maintain the aspect ratio and fit within 300 x 300
    let newWidth = originalWidth;
    let newHeight = originalHeight;
    console.log("Original Dimensions:", originalWidth, originalHeight);
    if (originalWidth > 300 || originalHeight > 300) {
      const aspectRatio = originalWidth / originalHeight;
  
      if (originalWidth > originalHeight) {
        newWidth = 300;
        newHeight = 300 / aspectRatio;
      } else {
        newHeight = 300;
        newWidth = 300 * aspectRatio;
      }
    }
  
    // Set the new dimensions to state
    setCanvasSize({
      width: newWidth,
      height: newHeight,
    });
    console.log("New Dimensions:", newWidth, newHeight);
  };

    
  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
      <div style={{borderRadius: '4px', display: 'inline-block', maxWidth: 400, padding: 20} }>
        {<canvas
          id="my-canvas"
          width={canvasSize.width}
          height={canvasSize.height}
          onLoad={handleImageLoad}
          style={{ border: borderStyle, maxWidth: '100%' }}
          
        ></canvas>}
        
        {!isLoaded && <div style={{color: '#fff', fontSize: '24px', fontWeight: 'bold' }}> Loading... <SpinningImage size={100}/> </div>}
        {isLoaded && <div style={{color: '#fff', fontSize: '24px', fontWeight: 'bold', borderRadius: '8px' }}>
          <br></br>
        <img src={attributionlogo} alt="Attribution"/>
        </div>}
        
      </div>
    </div>
  );
};

export default CameraComponent;
