import React, { useEffect, useState } from 'react';
import { StyleSheet, Text, View } from 'react-native';
import ScaleText from "react-scale-text";
import "./SnapExamples.css"

const SnapExamples = ({ videoSource, videoName, onButtonClick }) => {

    const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const mediaQuery = window.matchMedia('(max-width: 768px)'); // Adjust the breakpoint as needed

    const handleMobileChange = (e) => {
      setIsMobile(e.matches);
    };

    // Initial check
    handleMobileChange(mediaQuery);

    // Listen for changes in the viewport width
    mediaQuery.addListener(handleMobileChange);

    // Cleanup the listener when the component is unmounted
    return () => {
      mediaQuery.removeListener(handleMobileChange);
    };
  }, []);

    const handleButtonClick = () => {
        // Call the callback function and pass the videoName
        onButtonClick(videoName);
      };


    return (
        <div className="exampleBase">
        <div className='videoBase'>
            <div>
            <video  className='video' src={videoSource} type="video/mp4" autoPlay muted loop playsInline/>
            </div>
            <div  className='snapText'>

            <p className='seP'>{videoName}</p>
            <br/>
                <button onClick={handleButtonClick} style={{ fontSize:'24px' }}> Try </button>
            </div>


        </div>
        </div>
    );
}
  
export default SnapExamples;

//                    style={styles.backgroundVideo}