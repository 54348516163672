import React from 'react';
import { StyleSheet, Text, View } from 'react-native';
import "./Split.css"
import metaLogo from '../images/MetaLogo.png';



const OculusStart = () => {

  const headingStyle = {
    color: '#0082fb', // Replace with your desired color
  };

const textStyle = {
    color: 'black', // Replace with your desired color
  };

return (
    <div className="vision-sectionP">
    <div className="vision-container">
    <div className="image-container">
        <img src={metaLogo} alt="Vision" className='slnImage' />
      </div>
    <div className="text-container">
      <h2 style={headingStyle}>Oculus Start Member</h2>
        <p style={textStyle}>Meta (formerly Oculus) invited me to become a Start member which gives me access to a range of support and tools and helped me to develop and release several</p>
      </div>


    </div>
  </div>
);
}
  
export default OculusStart;