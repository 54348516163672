import React, { useEffect, useState } from 'react';
import { StyleSheet, Text, View } from 'react-native';
import ScaleText from "react-scale-text";
import "./VRGeneric.css"

const VRGeneric = ({ imageSource, gameName, gameLink }) => {

    const [isMobile, setIsMobile] = useState(false);

    const handleButtonClick = () => {
        // Call the callback function and pass the videoName
        console.log(gameLink);
        var newTab = window.open();
        newTab.opener = null;
        newTab.location.href = gameLink;
      };


    return (
        <div className="gexampleBase">
        <div className='gvideoBase'>
            <img className='gimg' src={imageSource}/>

        </div>
        <p className='sHero'>{gameName}</p>
        <div  className='gText'>
            
            <br/>

                <button onClick={handleButtonClick} style={{fontSize: '24px'}}> Try Today</button>
            </div>
        </div>
    );
}
  
export default VRGeneric;

//                    style={styles.backgroundVideo}