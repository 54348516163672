import React from 'react';
import { StyleSheet, Text, View } from 'react-native';
import "./VisionSection.css"
import logo from '../../images/niceComp.png';
const VisionSection = () => {


    return (
        <div className="vision-sectionh">
        <div className="vision-containerh">
          <div className="image-containerh">
            <img src={logo} alt="Vision" className="visionh-image" />
          </div>
          <div className="text-containerh">
            <p className='visionh2'> Let's Get Weird.</p>
            <p className='visionp'>Let's take your XR vision to the next step by creating a fun, <br></br>unique and interactive experience.</p>
            <a href="Business" className="cta-button">Learn More</a>
          </div>
        </div>
      </div>
    );
}
  
export default VisionSection;