import React from 'react';
import { StyleSheet, Text, View } from 'react-native';
import "./Split.css"
import research from '../images/Research.jpg';



const Sb = () => {

  const headingStyle = {
    color: '#0082fb', // Replace with your desired color
  };

  const textStyle = {
    color: 'black', // Replace with your desired color
  };

  return (
    <div className="vision-sectionP">
      <div className="vision-container">
      <div className="image-container">
          <img src={research} alt="Vision" className='slnImage' />
        </div>
        <div className="text-container">
          <h2 style={headingStyle}>Scottish Science Baccalaureate</h2>
          <p style={textStyle}> I conducted a year-long project investigating preferred input methods (hand tracking or motion controllers) in VR. I turned this research into a comprehensive research paper on the topic.</p>
        </div>
      </div>
    </div>
  );
}
  
export default Sb;