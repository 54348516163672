import React, { useState } from 'react';
import { Container, Nav, Navbar, NavDropdown } from 'react-bootstrap';
import logo from '../images/niceComp.png';

import 'bootstrap/dist/css/bootstrap.min.css';
import './NavBar.css';

function BasicExample() {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const handleDropdownToggle = (isOpen) => {
    setIsDropdownOpen(isOpen);
  };

  return (
    <Navbar expand="lg" className={`${isDropdownOpen ? 'naavbarCustom-true' : 'naavbarCustom-false'}`} sticky='top'>
      <Container>
        <Navbar.Brand href="home" className="navbar-brand-customH">
          {' '}
          <img
            alt=""
            src={logo}
            width="40"
            height="40"
            className="d-inline-block align-top"
          />{' '}
          Weird Eye Studios
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" id="basic-navbar-navY"/>
        <Navbar.Collapse id="basic-navbar-nav" conToggle={handleDropdownToggle} style={{ justifyContent: 'flex-end' }}>
          <Nav className="navMain">
            <Nav.Link href="home" className="navbar-brand-custom">
              Home
            </Nav.Link>
            <Nav.Link href="AR" className="navbar-brand-custom">
              Portfolio
            </Nav.Link>
            {/* <Nav.Link href="Business" className="navbar-brand-custom">
            Business
            </Nav.Link> */}
            <Nav.Link href="Physical" className="navbar-brand-custom">
            Products
            </Nav.Link>
            <Nav.Link href="Portfolio" className="navbar-brand-custom">
            Achievements
            </Nav.Link>
            <Nav.Link href="Contact" className="navbar-brand-custom">
              Contact
            </Nav.Link>
            {/* <Nav.Link href="Privacy" className="navbar-brand-custom">
              Privacy
            </Nav.Link> */}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default BasicExample;
