import React from 'react';
import './CardBlurb.css';
import {Dimensions} from 'react-native';
import BlurbVid from './blurbVid.mp4';

const CardBlurb = ({}) => {
    const { width } = Dimensions.get('window');
    const isMobile = width <= 1000;
    return (
        <div className={`${isMobile ? 'cardBlurbM' : 'cardBlurb'}`}>
            <video  className={`${isMobile ? 'blurb-videoM' : 'blurb-video'}`} src={BlurbVid} type="video/mp4" autoPlay muted loop playsInline/>
            <br></br>
            <text className='blurb-text'>This collection of physical cards come to life through Augmented Reality.</text>
            <br></br>
            <text className='blurb-text'>With super simple setup these cards are sure to give a smile to the recipient.</text>
        </div>
    );
};

export default CardBlurb;