import React from 'react';
import { StyleSheet, Text, View } from 'react-native';
import NavBar from '../CoreComponents/NavBar';

import VisionSection from '../PageComponents/Home/VisionSection';

import Constants from 'expo-constants';
import logo from '../images/weird-eye-logo.png';



const Home = () => {


  return (
<View style={styles.container} className="body">
  <NavBar style={styles.navBar}/>
  <VisionSection/>
</View>
  );
}

export default Home;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#374B51',

  },
  navBar: {
    width: '100%',
    top: 0,
  },
  
});
  