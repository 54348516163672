import React from 'react';
import { StyleSheet, Text, View } from 'react-native';
import "./Split.css"
import spectaclesLogo from '../images/spectacles.jpg';



const Spectacles = () => {

  const headingStyle = {
    color: '#ffea00', // Replace with your desired color
  };

  const textStyle = {
    color: 'black', // Replace with your desired color
  };

  return (
    <div className="vision-sectionP">
      <div className="vision-container">
      <div className="image-container">
          <img src={spectaclesLogo} alt="Vision" className='slnImage' />
        </div>
        <div className="text-container">
          <h2 style={headingStyle}>Spectacles</h2>
          <p style={textStyle}>I had the honour of working on a pre-release AR product, the Snapchat Spectacles. This partnership resulted in a marketing project and me being left as one of the 6 featured developers on their <a href="https://www.spectacles.com/uk/creators/">Developer Page</a>.</p>
        </div>
=
      </div>
    </div>
  );
}
  
export default Spectacles;